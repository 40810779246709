<!-- v2.6.2 lzk 2022.12.14  -->
<template>
  <a-spin :spinning="spinning">
    <div v-if="detailInfo">
      <a-card class="head-wrapper" :bordered="false">
        <div class="head-card flex-layout">
          <div>
            <div class="flex-layout">
              <span class="label">流程主题：</span>
              <a-tooltip v-if="detailInfo.fileName?.length > 12" placement="topLeft">
                <template #title>
                  {{ detailInfo.fileName }}
                </template>
                <span class="text-elips">{{ detailInfo.fileName }}</span>
              </a-tooltip>
              <span v-else class="text-width">{{ detailInfo.fileName }}</span>
            </div>
            <div class="margin-T8 flex-layout">
              <span class="label people-lable">申请人：</span>
              <a-tooltip v-if="detailInfo.staffName?.length > 12" placement="topLeft">
                <template #title>
                  {{ detailInfo.staffName }}
                </template>
                <span class="text-elips">{{ detailInfo.staffName }}</span>
              </a-tooltip>
              <span v-else class="text-width"> {{ detailInfo.staffName }}</span>
              <span class="depart label">部门：</span>
              <span>{{ detailInfo.departmentName || '--' }}</span>
            </div>
          </div>
          <!-- <div class="apply-status-wrapper">
            <p>申请状态</p>
            <span class="apply-status">
              <span
                class="dotted"
                :style="{ background: examineStatusObj[detailInfo.examineStatus ?? 0].color }"
              ></span>
              {{ examineStatusObj[detailInfo.examineStatus].name }}</span
            >
          </div> -->
        </div>
      </a-card>
      <div class="padding24">
        <div class="file-wrapper">
          <a-card title="签约文件" :bordered="false">
            <!-- 签署完成展示该下拉 -->
            <!-- <template #extra>
              <a-dropdown v-if="detailInfo.contractStatus === 3">
                <div class="down-file-text">
                  <DownloadOutlined />
                  盖印文件/存证报告
                </div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="downFiles(1)">
                      <span>下载盖印文件</span>
                    </a-menu-item>
                    <a-menu-item @click="downFiles(2)">
                      <span>下载存证报告</span>
                    </a-menu-item>
                    <a-menu-item @click="downFiles(3)">
                      <span>全部下载</span>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template> -->

            <span v-if="!detailInfo.documentApplyFileList">无</span>
            <section style="display: inline-block; vertical-align: top;width:80%;" v-else>
              <div v-viewer="otherOptions" :images="detailInfo.imgList">
                <div class="applyFileBox" v-for="(it, index) in detailInfo.documentApplyFileList" :key="it.fileId">
                  <div class="seal-imgs" style="position: relative;">
                    <a v-if="fileTypeObj[it.suffix]">
                      <img :src="getImgUrl(it.suffix)" class="seal-img" alt="" />
                    </a>
                    <div class="downloadFileBox" @click="downloadFile(it.fullFile)" v-if="fileTypeObj[it.suffix]">
                      <i class="iconfont icon-xiazai icons" style="color:white;font-size:12px;"></i>
                    </div>
                    <img
                      v-else
                      :src="it.fullFile"
                      alt=""
                      @error="reImg($event, it.fullFile)"
                      @click="previewImg(it.fullFile, index, 'applyFile')"
                      class="seal-img"
                    />
                  </div>
                  <div style="flex:1" :title="it.fileName" class="single-wrap">{{ it.fileName ?? '无' }}</div>
                </div>
              </div>
            </section>
          </a-card>
        </div>
        <a-card :bordered="false">
          <template #title>
            {{ detailInfo.sealName }}
          </template>
          <approval-time-line-list :approvalList="detailInfo.sealFlowShow || []" />
        </a-card>
        <a-card>
          <template #title>
            <div class="flex-layout ordinal-title">
              <h1>签署信息</h1>
              <div>
                <img src="@/assets/images/sealMessage/ordinal-icon.png" />
                <span>{{ detailInfo.ordinal ? '顺序签署' : '无序签署' }}</span>
              </div>
            </div>
          </template>
          <template #extra>
            签署截止日期：<span class="date-block">{{ moment(detailInfo.expireTime).format('YYYY-MM-DD') }}</span>
          </template>
          <!-- 签署方顺序 -->
          <SignCardDet :signList="detailInfo.signatories" />
        </a-card>
        <a-card title="其他信息" :bordered="false">
          <p>备注：{{ detailInfo.applyText || '无' }}</p>
        </a-card>
      </div>

      <section class="submit-foot" v-if="detailType === 2 && (detailInfo.ableCancel || detailInfo.ableReSubmit)">
        <a-button @click.prevent="handleCancel" class="cancel-btn">取消</a-button>
        <a-button @click="openModal()" :loading="loading" class="submit-btn" v-if="detailInfo.ableCancel"
          >撤销申请</a-button
        >
        <a-button
          @click="$router.push(`/seal/apply/addElec?documentId=${documentId}`)"
          :loading="loading"
          class="submit-btn"
          v-if="detailInfo.ableReSubmit"
          >重新申请</a-button
        >
      </section>
    </div>
  </a-spin>
</template>
<script setup>
import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons-vue'
import ApprovalTimeLineList from '@/views/components/approvalTimeLineList'
import SignCardDet from '@/views/seal/apply/components/signCardDet.vue'
import { elecApplyDetail, downFile } from '@/apis/elecApply'
import { backoutSeal } from '@/apis/seal'
import { getDetailsApplyType } from '@/apis/user'
import { carbonCopyEleDetails } from '@/apis/approval'
import { Modal } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { examineStatusObj, fileTypeObj } from '@/utils/constData'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
const otherOptions = {
  navbar: false,
  title: false,
  transition: false
}
const spinning = ref(false)
const submitLoading = ref(false)
const router = useRouter()
const route = useRoute()
const detailInfo = ref()
const previewVisible = ref(false)
const reverseImg = ref(false)
const photoIndex = ref(0)
const documentId = computed(() => route.query.documentId) //申请Id
const detailType = ref() // 类型 1 政务版  2 企业版

const initDetail = async id => {
  spinning.value = true
  const { data } = await carbonCopyEleDetails(id)
  detailInfo.value = data
  spinning.value = false
}
// 撤销申请
const onSubmit = () => {}
// 取消
const handleCancel = () => {
  router.go(-1)
}
const getImgUrl = src => {
  return require('@/assets/images/' + fileTypeObj[src])
}
const previewImg = (src, i, type) => {
  if (!src) return
  reverseImg.value = false
  detailInfo.value.imgList = []
  if (type == 'printImg') {
    detailInfo.value.documentFileRelVO?.map(item => {
      if (item.fullFile) detailInfo.value.imgList.push(item.fullFile)
    })
    reverseImg.value = true
  } else if (type == 'applyFile') {
    detailInfo.value.documentApplyFileS?.map(item => {
      if (!fileTypeObj[item.suffix]) {
        detailInfo.value.imgList.push(item.fullFile)
      }
    })
  } else if (type == 'remoteFile') {
    detailInfo.value.documentRemoteVice?.map(item => {
      detailInfo.value.imgList.push(item.fullFile)
    })
  }
  photoIndex.value = detailInfo.value.imgList.indexOf(src)
  previewVisible.value = true
}
const reImg = (e, src, sign) => {
  console.log('12312312')
  const parentNode = e.target.parentNode
  const tipsSpan = document.createElement(sign)
  if (sign === 'a') {
    const tipsText = document.createTextNode('点击下 载该附件查看')
    tipsSpan.appendChild(tipsText)
    tipsSpan.addEventListener('click', () => {
      window.location.href = src.replace('.com//', '.com/') + '?upload=true'

      setTimeout(() => {
        cmsNotice('success', '正在为你下载，请耐心等待')
      }, 200)
    })
    parentNode.removeChild(e.target)
    parentNode.appendChild(tipsSpan)
  } else {
    const tipsDiv = document.createElement('div')
    tipsDiv.id = 'error-imgs'
    tipsDiv.style.height = '104px'
    tipsDiv.style.width = '104px'
    tipsDiv.style.background = '#F2F2F2'
    tipsDiv.style.textAlign = 'center'
    // tipsDiv.style.transform = 'rotateZ(180deg)'
    tipsDiv.style.color = '#B1B1B1'
    tipsDiv.style.padding = '40px 0 0 0'
    const tipsI = document.createElement('i')
    const tipsText = document.createTextNode(src ? '加载失败' : '同步失败')
    tipsI.className = 'iconfont icon-tupianjiazaishibai1 error-img'
    tipsI.style.fontSize = '30px'
    tipsI.style.color = '#D9D9D9'
    tipsI.style.marginBottom = '10px'
    tipsI.style.height = '25px'
    tipsI.style.display = 'block'
    tipsDiv.appendChild(tipsI)
    tipsDiv.appendChild(tipsText)
    parentNode.removeChild(e.target)
    parentNode.style.border = 'none'
    parentNode.style.padding = '0'
    parentNode.appendChild(tipsDiv)
  }

  // parentNode.removeChild(e.target)
  // parentNode.appendChild(tipsSpan)
}
// 是否为企业版  是否可以撤销 重新提交
const getDetailsType = async () => {
  const { data } = await getDetailsApplyType()
  detailType.value = data
}
const downloadFile = src => {
  window.location.href = src.replace('.com//', '.com/') + '?upload=true'
  setTimeout(() => {
    cmsNotice('success', '正在为你下载，请耐心等待')
  }, 200)
}
//  下载盖印文件……
// const downFiles = async fileType => {
//   const res = await downFile(detailInfo.value.contractId, { fileType })
//   const link = document.createElement('a') //创建a标签
//   let blob = res.data //如果后台返回的直接是blob对象类型，直接获取数据
//   console.log(res.headers)
//   let fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1]) //拆解获取文件名，
//   link.style.display = 'none' //隐藏
//   const url = window.URL || window.webkitURL || window.moxURL
//   link.href = url.createObjectURL(blob)
//   link.download = decodeURI(fileName) //下载的流程主题
//   link.click() //触发click
//   window.URL.revokeObjectURL(url) //  移除标签
// }

//撤销申请
const openModal = () => {
  Modal.confirm({
    title: '是否撤销！',
    content: '请确认是否执行【撤销申请】操作?',
    centered: true,
    icon: createVNode(ExclamationOutlined),
    onOk: async () => {
      const { success, code } = await backoutSeal({ id: documentId.value })
      if (success) {
        cmsNotice('success', '您的申请已撤销成功！')
        initDetail(documentId.value) // 初始化获取详情
      } else if (code === 1068) {
        router.push('/seal/apply')
      }
    }
  })
}
// 方法调用
getDetailsType() //获取是否是企业版
initDetail(documentId.value) // 初始化获取详情
</script>
<style lang="less" scoped>
.head-card {
  justify-content: space-between;
}
.apply-status {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}
.depart {
  padding-left: 52px;
  letter-spacing: 10px;
}
.margin-T8 {
  margin-top: 8px;
}
.head-wrapper {
  margin-top: -36px;
  // border: none;
  .label {
    color: rgba(0, 0, 0, 0.85);
    + span {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.padding24 {
  padding: 24px;
  > div {
    margin-bottom: 16px;
  }
}
:deep(.ant-card-extra .down-file-text) {
  color: #c3161c;
  cursor: pointer;
}
.ordinal-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  div {
    margin-left: 16px;
    img {
      padding-right: 4px;
      vertical-align: text-bottom;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.date-block {
  padding: 2px 8px;
  border-radius: 1px;
  color: #f04136;
  background: rgba(240, 65, 54, 0.1);
}
.text-elips {
  display: inline-block;
  max-width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.apply-status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.dotted {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  transform: translateY(-2px);
}
.applyFileBox {
  display: flex;
  align-items: center;
  &:hover .downloadFileBox {
    display: block;
  }
  .downloadFileBox {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    height: 15px;
    line-height: 15px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
}
.seal-imgs {
  border: 1px solid #dbdbdb;
  padding: 5px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 0;
  &:nth-last-of-type(1) {
    margin-right: 0;
  }
  .seal-img {
    width: 44px;
    height: 44px;
  }
}
.single-wrap {
  color: rgba(51, 51, 51, 0.65);
}
.text-width {
  width: 12em;
}
.people-lable {
  letter-spacing: 4px;
}
</style>
